<template>
  <b-form @submit.prevent="$emit('processUsuario', usuario)">
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Imagen -->
        <b-col
          cols="12"
          md="12"
        >

          <b-form-group
            label-for="imagen"
          >
          <!-- {{usuario.imagen}} -->
            <!-- Media -->
            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="usuario.imagen"
                  :text="usuario.nombre == '' ? 'P' : avatarText(usuario.nombre)"
                  size="90px"
                  rounded
                  variant="light-success"
                  :title="usuario.nombre == '' ? 'P' : avatarText(usuario.nombre)"
                />
              </template>
              <h4 class="mb-1">
                <span v-if="usuario.correo">
                  {{ usuario.correo }}
                </span>
                <span v-if="usuario.correo && usuario.nombre">
                  -
                </span>
                <span v-if="usuario.nombre">
                  {{ usuario.nombre }}
                </span>
              </h4>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
                >
                  <input
                    :id="usuario.imagen"
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="inputImageRenderer"
                    accept="image/png,image/jpeg,image/jpg"
                    @change="onFileSelected"
                  >
                  <span class="d-none d-sm-inline">Seleccionar</span>
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click="usuario.imagen = ''"
                >
                  <span class="d-none d-sm-inline">Eliminar</span>
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>
          </b-form-group>
        </b-col>

        <!-- Field: Correo -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Correo"
            label-for="correo"
          >
            <b-form-input
              id="correo"
              v-model="usuario.correo"
              :state="!$v.usuario.correo.$invalid"
              type="email"
              placeholder="Introduce el correo"
              @input="$v.usuario.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="correoInfo"
            >
              El campo correo
              <span v-if="!$v.usuario.correo.required"> es requerido.</span>
              <span v-if="!$v.usuario.correo.maxLength"> debe tener max 80 caracteres.</span>
              <span v-if="!$v.usuario.correo.email"> debe ser un correo valido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Nombre -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nombre"
            label-for="nombre"
          >
            <b-form-input
              id="nombre"
              v-model="usuario.nombre"
              :state="!$v.usuario.nombre.$invalid"
              placeholder="Introduce el nombre"
              @input="$v.usuario.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="nombreInfo"
            >
              El campo nombre
              <span v-if="!$v.usuario.nombre.required"> es requerido.</span>
              <span v-if="!$v.usuario.nombre.maxLength"> debe tener max 80 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Contraseña"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="usuario.password"
              :state="!$v.usuario.password.$invalid"
              type="password"
              placeholder="Introduce la contraseña"
              @input="$v.usuario.$touch"
              @keyup="changePassword"
            />
            <b-form-valid-feedback v-if="!validationPassword && !validarPassword">
              Si este campo está vacío, será omitido al editar el usuario.
            </b-form-valid-feedback>
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="passwordInfo"
            >
              El campo contraseña
              <span v-if="!$v.usuario.password.required"> es requerido.</span>
              <span v-if="!$v.usuario.password.minLength"> debe tener al menos 6 caracteres.</span>
              <span v-if="!$v.usuario.password.maxLength"> debe tener max 30 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Telefono Interno -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Teléfono Interno"
            label-for="telefonoInterno"
          >
            <b-form-input
              id="telefonoInterno"
              v-model="telefonoInterno"
              :state="!$v.usuario.telefonoInterno.$invalid"
              placeholder="Introduce el teléfono interno"
              @input="$v.usuario.$touch"
              @keyup="formatearTelefono"
              @blur="formatearTelefono"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="telefonoInfo"
            >
              El campo teléfono interno
              <span v-if="!$v.usuario.telefonoInterno.required"> es requerido.</span>
              <span v-if="!$v.usuario.telefonoInterno.maxLength"> debe tener max 3 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Telefono -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Teléfono"
            label-for="telefono"
          >
            <b-form-input
              id="telefono"
              v-model="telefono"
              :state="!$v.usuario.telefono.$invalid"
              placeholder="Introduce el teléfono"
              @input="$v.usuario.$touch"
              @keyup="formatearTelefono"
              @blur="formatearTelefono"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="telefonoInfo"
            >
              El campo teléfono
              <span v-if="!$v.usuario.telefono.required"> es requerido.</span>
              <span v-if="!$v.usuario.telefono.maxLength"> debe tener max 25 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Estado -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Estado"
            label-for="estado"
          >
            <b-form-select
              v-model="usuario.estado"
              placeholder="Selecciona un estado"
              :state="!$v.usuario.estado.$invalid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="estadoOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="estado"
              @input="$v.usuario.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="estadoInfo"
            >
              El campo estado
              <span v-if="!$v.usuario.estado.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: tipoUsuario -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Rol"
            label-for="tipoUsuario"
          >
            <b-form-select
              v-model="usuario.tipoUsuario"
              placeholder="Selecciona un rol"
              :state="!$v.usuario.tipoUsuario.$invalid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rolOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="tipoUsuario"
              @input="$v.usuario.$touch"
              @change="seleccionaRol(usuario.tipoUsuario)"
            />
            <b-form-invalid-feedback
              v-if="$v.usuario.$dirty"
              id="tipoUsuarioInfo"
            >
              El campo rol
              <span v-if="!$v.usuario.tipoUsuario.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <permisosForm
      v-if="permisos"
      :permisosUsuario.sync="usuario.permissions"
      :permissionsUsuario.sync="usuario.permissionsUsuario"
    />

    <!-- Action Buttons -->
    <btnSubmit
      :btnDisabled="$v.usuario.$invalid"
      :btnText="usuarioSubmit"
    />
  </b-form>
</template>

<script>
import {
  BMedia, BButton, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BFormValidFeedback, BFormSelect,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { validationMixin } from 'vuelidate'
import {
  required, minLength, email, maxLength,
} from 'vuelidate/lib/validators'
import { formatearTelefono } from '@/mixins/forms'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')
const permisosForm = () => import('@/layouts/components/Usuarios/UsuariosFormPermisos.vue')

export default {
  components: {
    BMedia,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BFormInvalidFeedback,
    BFormValidFeedback,
    btnSubmit,

    permisosForm,
  },
  props: {
    usuario: {
      type: Object,
      required: true,
    },
    usuarioSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Usuario',
    },
    validarPassword: {
      type: Boolean,
      default: true, // Texto del boton
    },
  },
  mixins: [validationMixin, formatearTelefono],
  data() {
    return {
      permisos: true,
      validationPassword: null,
      telefono: this.usuario.telefono,
      telefonoInterno: this.usuario.telefonoInterno,
    }
  },
  watch: {
    telefono() {
      this.usuario.telefono = this.telefono
    },
    telefonoInterno() {
      this.usuario.telefonoInterno = this.telefonoInterno
    },
  },
  validations() {
    if (this.validationPassword) {
      return {
        usuario: {
          nombre: {
            required,
            maxLength: maxLength(80),
          },
          telefono: {
            required,
            maxLength: maxLength(25),
          },
          telefonoInterno: {
            required,
            maxLength: maxLength(3),
          },
          correo: {
            required,
            maxLength: maxLength(80),
            email,
          },
          password: {
            required,
            minLength: minLength(6),
            maxLength: maxLength(30),
          },
          estado: {
            required,
          },
          tipoUsuario: {
            required,
          },
        },
      }
    }
    if (!this.validationPassword) {
      return {
        usuario: {
          nombre: {
            required,
            maxLength: maxLength(80),
          },
          telefono: {
            required,
            maxLength: maxLength(25),
          },
          telefonoInterno: {
            required,
            maxLength: maxLength(3),
          },
          correo: {
            required,
            maxLength: maxLength(80),
            email,
          },
          password: {
            maxLength: maxLength(30),
          },
          estado: {
            required,
          },
          tipoUsuario: {
            required,
          },
        },
      }
    }
    return this.usuario
  },
  setup(props) {
    const rolOptions = [
      { value: null, text: 'Selecciona un rol' },
      { value: 'Admin', text: 'Admin' },
      { value: 'Bodeguero', text: 'Bodeguero' },
      { value: 'Vendedor', text: 'Vendedor' },
      { value: 'Ingeniero', text: 'Ingeniero' },
    ]

    const estadoOptions = [
      { value: null, text: 'Selecciona un estado' },
      { value: 'Activo', text: 'Activo' },
      { value: 'Inactivo', text: 'Inactivo' },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.usuario.imagen = base64
      // console.log(base64)
    })

    return {
      rolOptions,
      estadoOptions,
      avatarText,

      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  mounted() {
    this.validationPassword = this.validarPassword
    if (this.usuario.tipoUsuario === 'Admin' || this.usuario.tipoUsuario === null) {
      this.permisos = false
    }
  },
  methods: {
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.usuario.imagen = e.target.result
      }
    },
    seleccionaRol(tipoUsuario) {
      if (tipoUsuario === 'Admin') {
        this.permisos = false
      } else {
        this.permisos = true
      }
    },
    changePassword() {
      if (this.validarPassword === false) {
        if (this.usuario.password.length !== 0) {
          this.validationPassword = true
        } else {
          this.validationPassword = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
